<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card class="pt-2 pb-3 mb-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-layers"
                                    clearable
                                    solo
                                    v-model="commodity"
                                    :items="commodities"
                                    item-value="commodity_id"
                                    item-text="descr"
                                    label="Commodity"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                    @change="(event) => getColour()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-coat-rack"
                                    clearable
                                    solo
                                    v-model="coat_mass"
                                    :items="coat_masss"
                                    item-value="coat_mass"
                                    :item-text="item => 'AS' + item.coat_mass"
                                    label="Coat (AS - AZ)"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="descr"
                                    label="Colour"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn color="info" elevation="2" @click="search()" style="padding: 19px;" class="border-12"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2">
                            <v-btn
                                class="mr-2 rounded-l mt-7"
                                color="error"
                                elevation="2"
                                large
                                @click="search()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                                Search
                            </v-btn>
                        </v-col>          -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" outlined>
                    <v-card-title class="text-h6">
                        <v-chip class="primary text-white p-4">
                            Total Quantity : {{ result ? (result.length > 0 ? result[0].qty : '' ): ''}} Item
                        </v-chip>

                        <v-chip class="primary text-white p-4 ml-5">
                            Total Weight : {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_wgt) : '' ): ''}} Ton
                        </v-chip>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="coils"
                            :options.sync="options"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            :server-items-length="totalItems"
                            :page.sync="pagination"
                            :search="searchItem"
                            page-count="10"
                            fixed-header
                            height="450"
                        >
                            <template v-slot:top>
                                <v-text-field solo v-model="searchItem" label="Search Prod Code... " class="mx-4 border-12" @keyup.enter="search()" style="max-width: 300px;"></v-text-field>
                            </template>
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.thick) }}
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.width) }}
                            </template>
                            <template v-slot:[`item.total_wgt`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.total_wgt) }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span>Show</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-dialog v-model="dialog" max-width="1000px">                
                        <v-card>
                            <v-card-title>Detail Coil</v-card-title>
                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-data-table
                                                :headers="header_coil"
                                                :items="detail_coil"
                                                :options.sync="option_coil"
                                                :loading="loading"
                                                loading-text="Please wait, retrieving data"
                                                :server-items-length="totalItem_coil"
                                                :page.sync="pagination_coil"
                                                page-count="10"
                                                class="elevation-1"
                                            >
                                                <!-- <template v-slot:[`item.qty`]="{ item }">
                                                    {{ $store.getters.convertToCurrency(item.qty) }}
                                                </template>
                                                <template v-slot:[`item.wgt`]="{ item }">
                                                    {{ $store.getters.convertToCurrency(item.wgt) }}
                                                </template> -->
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            detail: false,
            start_period: '',
            end_period: '',
            menu: false,
            modal: false,
            loading: false,
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Free Coil Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machines: [],
            machine: '',
            commodities: [],
            commodity: '',
            options: {},
            headers: [
                { text: 'Prodcode', value: 'prod_code' },
                { text: 'Descr', value: 'descr' },
                { text: 'Brand', value: 'brand_desc' },
                { text: 'Thick', value: 'thick' },
                { text: 'Width', value: 'width' },
                { text: 'Grade', value: 'grade_id' },
                { text: 'AZ / AS', value: 'AZ' },
                { text: 'Colour', value: 'color' },
                { text: 'Quality', value: 'quality_id' },
                { text: 'Total Weight', value: 'total_wgt' },
                { text: 'Qty', value: 'qty' },
                { text: 'Action', value: 'action' }
            ],
            coils: [],
            totalItems: 10,
            pagination: 1,
            searchItem: '',
            result: null,
            detail: {},
            dialog: false,
            header_coil: [
                { text: 'Mill ID', value: 'mill_id' },
                { text: 'Coil ID', value: 'coil_id' },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Descr', value: 'descr' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Stat', value: 'stat' }
            ],
            detail_coil: [],
            option_coil: {},
            totalItem_coil: 10,
            pagination_coil: 1
        }
    },
    mounted(){
        this.getCommodity()
        this.getBrand()
        this.getCoatMass()
        this.getGrade()
        this.getThick()
        this.getWidth()
        this.getColour()
        this.getQuality()
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getCommodity(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/commodity`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.commodities = res.data
            });
        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/grade`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/thicks?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/width?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getColour(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/colour?brand=${this.brand ? this.brand : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colours = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/quality?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        async search(page = 1, itemsPerPage = 10){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/production_report/free-coil?commodity=${this.commodity ? this.commodity : ''}&brand=${this.brand ? this.brand : ''}&coat=${this.coat_mass ? this.coat_mass : ''}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}&search=${this.searchItem ? this.searchItem : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.result = res.data.result
                this.loading = false
                this.coils = res.data.data.data
                this.totalItems = res.data.data.total
                this.pagination = res.data.data.current_page
            })
        },
        showItem(value){
            console.log(value);
            this.dialog = true
            this.detail = value
            this.detail_coil = []
            this.getDetail(1,10)
        },
        async getDetail(page = 1, itemsPerPage = 10){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/production_report/free-coil/show?prod_code=${this.detail.prod_code}&commodity=${this.commodity ? this.commodity : ''}&brand=${this.brand ? this.brand : ''}&coat=${this.coat_mass ? this.coat_mass : ''}&grade=${this.grade ? this.grade : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&colour=${this.colour ? this.colour : ''}&quality=${this.quality ? this.quality : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.detail_coil = res.data.data
                this.totalItem_coil = res.data.total
                this.pagination_coil = res.data.current_page
            })
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                this.search(page, itemsPerPage)
            },
        deep: true
        },
        option_coil: {
            handler () {
                if (this.detail) {
                    const { page, itemsPerPage } = this.option_coil
                    this.getDetail(page, itemsPerPage)
                }
            },
        deep: true
        }
    }
  }
</script>